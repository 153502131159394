//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex'
import { TOP_NAV_HEIGHT } from '@/constants/app/appConstants'

import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'

import ReachLogo from '@/assets/svg/app/ReachLogo.vue'

const GlobalMenu = () => import('@/drawers/GlobalMenu.vue')

export default {
  name: 'TopNav',
  components: {
    UserProfilePhoto,
    ReachLogo,
  },
  props: {
    showSideItems: Boolean,
  },
  data() {
    return {
      TOP_NAV_HEIGHT,
    }
  },
  computed: {
    ...mapState([
      'isDev',
      'isMobile',
      'isWebview',
      'waitingAuth',
      'signedInUserId',
      'signedInUser',
    ]),
    ...mapGetters('auth', [
      'inAuth',
    ]),
    ...mapState('brandCampaignDashboard', [
      'coverInfo',
    ]),
    ...mapState('agent', [
      'agency',
    ]),
    inHome() {
      return this.$route.matched.some(({ name }) => name === 'home')
    },
    isProfile() {
      return this.$route.matched.some(({ name }) => name === 'userPage' || name === 'userLive')
    },
    inCampaign() {
      return this.$route.matched.some(({ name }) => name === 'campaignSplashScreen')
    },
    inDashboard() {
      return this.$route.matched.some(({ name }) => (name === 'campaignDashboard') || (name === 'campaignGroupDashboard'))
    },
    authExists() {
      return this.$router.resolve({ name: 'auth' }).href !== '/'
    },
    userPageExists() {
      return this.$router.resolve({ name: 'userPage', params: { username: 'test' } }).href !== '/'
    },

    logoRoute() {
      if (this.isWebview) return undefined

      return { name: this.$route.matched.find(({ name }) => name).name }
    },
  },
  methods: {
    ...mapActions('overlays', [
      'CLEAR_OVERLAYS',
    ]),

    openMenu() {
      this.$store.dispatch('overlays/ADD_FULLSCREEN_POPUP', {
        component: GlobalMenu,
        popupType: 'rightDrawer',
      })
    },
  },
}
